.topbarStyling{
  background-color: #2c82c5;
  flex: 1;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbarItem{
  color: white;
  flex: 1;
  display: flex;
}

.funeralHeader{
  color: white;
  background-color: #2c82c5;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  border-top: 2px solid #cdcdcd;
  border-right: 2px solid #cdcdcd;
  border-left: 2px solid #cdcdcd;
}

.funeralSchedulesHeight{
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #cdcdcd;
  height: calc(((100vh - 75px)/2) - 94px);
  overflow: hidden;
}

.funeralSchedulesWide{
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #cdcdcd;
  height: calc((100vh - 75px) - 104px);
  overflow: hidden;
}

.funeralEntriesFlexBox{
  display: flex;
  color: white;
  border-bottom: 2px solid #cdcdcd;
}

.funeralEntries{
  display: flex;
  color: white;
  background-color: #23232e;
  border-bottom: 2px solid #11121c;
  min-height: 60px;
}

.funeralDT{
  font-size: 13px;
  flex: 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.funeralName{
  font-size: 14px;
  flex: 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.funeralLoc{
  font-size: 14px;
  flex: 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.funeralPastor{
  font-size: 14px;
  flex: 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.funeralNotes{
  font-size: 11px;
  flex: 3;
  padding: 2px 10px;
  align-items: center;
  display: flex;
}

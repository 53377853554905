

.hospitalHeader{
  color: white;
  background-color: #2c82c5;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  border-top: 2px solid #cdcdcd;
  border-right: 2px solid #cdcdcd;
  border-left: 2px solid #cdcdcd;
}

.hospitalSchedulesHeight{
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #cdcdcd;
  height: calc(((100vh - 75px)/2) - 94px);
  overflow: hidden;
}

.hospitalSchedulesWide{
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #cdcdcd;
  height: calc((100vh - 75px) - 104px);
  overflow: hidden;
}

.hospitalLabelsFlexBox{
  display: flex;
  color: white;
  border-bottom: 2px solid #cdcdcd;
}

.hospitalEntries{
  display: flex;
  color: white;
  background-color: #23232e;
  border-bottom: 2px solid #11121c;
  min-height: 60px;
}

.hospitalDT{
  font-size: 13px;
  flex: 2;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hospitalName{
  font-size: 14px;
  flex: 2;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.hospitalLoc{
  font-size: 14px;
  flex: 2;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.hospitalPastor{
  font-size: 14px;
  flex: 2;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.hospitalUpdate{
  font-size: 11px;
  flex: 3;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.hospitalComment{
  font-size: 11px;
  flex: 3;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

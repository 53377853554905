.pageBackground{
  background-color: #11121c;
  height: calc(100vh);
}

.pageTopbar{
  height: 75px;
}

.pageBodyHeight{
  height: calc(100vh - 115px);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.pageBodyWide{
  height: calc(100vh - 115px);
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.pageWidgetRight{
  flex: 1;
  margin-left: 10px;
}

.pageWidgetLeft{
  flex: 1;
  margin-right: 10px;
}

.pageWidgetTop{
  flex: 1;
  margin-bottom: 10px;
}

.pageWidgetBottom{
  flex: 1;
  margin-top: 10px;
}
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

.funeralHeader{
  color: white;
  background-color: #2c82c5;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  border-top: 2px solid #cdcdcd;
  border-right: 2px solid #cdcdcd;
  border-left: 2px solid #cdcdcd;
}

.funeralSchedulesHeight{
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #cdcdcd;
  height: calc(((100vh - 75px)/2) - 94px);
  overflow: hidden;
}

.funeralSchedulesWide{
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #cdcdcd;
  height: calc((100vh - 75px) - 104px);
  overflow: hidden;
}

.funeralEntriesFlexBox{
  display: flex;
  color: white;
  border-bottom: 2px solid #cdcdcd;
}

.funeralEntries{
  display: flex;
  color: white;
  background-color: #23232e;
  border-bottom: 2px solid #11121c;
  min-height: 60px;
}

.funeralDT{
  font-size: 13px;
  flex: 1 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.funeralName{
  font-size: 14px;
  flex: 1 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.funeralLoc{
  font-size: 14px;
  flex: 1 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.funeralPastor{
  font-size: 14px;
  flex: 1 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.funeralNotes{
  font-size: 11px;
  flex: 3 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
}



.hospitalHeader{
  color: white;
  background-color: #2c82c5;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  border-top: 2px solid #cdcdcd;
  border-right: 2px solid #cdcdcd;
  border-left: 2px solid #cdcdcd;
}

.hospitalSchedulesHeight{
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #cdcdcd;
  height: calc(((100vh - 75px)/2) - 94px);
  overflow: hidden;
}

.hospitalSchedulesWide{
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #cdcdcd;
  height: calc((100vh - 75px) - 104px);
  overflow: hidden;
}

.hospitalLabelsFlexBox{
  display: flex;
  color: white;
  border-bottom: 2px solid #cdcdcd;
}

.hospitalEntries{
  display: flex;
  color: white;
  background-color: #23232e;
  border-bottom: 2px solid #11121c;
  min-height: 60px;
}

.hospitalDT{
  font-size: 13px;
  flex: 2 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hospitalName{
  font-size: 14px;
  flex: 2 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.hospitalLoc{
  font-size: 14px;
  flex: 2 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.hospitalPastor{
  font-size: 14px;
  flex: 2 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.hospitalUpdate{
  font-size: 11px;
  flex: 3 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.hospitalComment{
  font-size: 11px;
  flex: 3 1;
  padding: 2px 10px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.pageBackground{
  background-color: #11121c;
  height: calc(100vh);
}

.pageTopbar{
  height: 75px;
}

.pageBodyHeight{
  height: calc(100vh - 115px);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.pageBodyWide{
  height: calc(100vh - 115px);
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.pageWidgetRight{
  flex: 1 1;
  margin-left: 10px;
}

.pageWidgetLeft{
  flex: 1 1;
  margin-right: 10px;
}

.pageWidgetTop{
  flex: 1 1;
  margin-bottom: 10px;
}

.pageWidgetBottom{
  flex: 1 1;
  margin-top: 10px;
}
.topbarStyling{
  background-color: #2c82c5;
  flex: 1 1;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbarItem{
  color: white;
  flex: 1 1;
  display: flex;
}
